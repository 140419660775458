<div class="dialog-title">
  <div class="row">
    <div class="col-md-12">
      <div class="dialog-title">
        <h4 mat-dialog-title>Edit account</h4>
      </div>
    </div>
  </div>
</div>
<div class="dialog-form">
  <form [formGroup]="editAccountForm" (ngSubmit)="submitEditAccountForm(editAccountForm)">
    <div class="form-section">
      <div class="row">
        <div class="col-md-12">
          <div class="form-section-title">
            <h6>Account Information</h6>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-label>User Role <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="UserRole" placeholder="" (selectionChange)="userRoleChanged()">
              <mat-option value="Admin" *ngIf="authService.activeAccount.role === 'Admin'">Admin</mat-option>
              <mat-option value="Manager" *ngIf="authService.activeAccount.role === 'Admin' || authService.activeAccount.role === 'Manager'">Manager</mat-option>
              <mat-option value="Member">Member</mat-option>
              <mat-option value="Claims">Claims</mat-option>
              <mat-option value="ReadOnly">ReadOnly</mat-option>
            </mat-select>
            <mat-error *ngIf="editAccountForm.controls['UserRole'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Email <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" formControlName="Email" matInput maxlength="100">
            <mat-error *ngIf="editAccountForm.controls['Email'].hasError('required')">This field is required</mat-error>
            <mat-error *ngIf="editAccountForm.controls['Email'].hasError('email')">Must be a valid email address</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-label>First Name <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" formControlName="FirstName" matInput maxlength="50">
            <mat-error *ngIf="editAccountForm.controls['FirstName'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Last Name <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" formControlName="LastName" matInput maxlength="50">
            <mat-error *ngIf="editAccountForm.controls['LastName'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-label>Phone Number <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <input type="text" formControlName="PhoneNumber" matInput mask="(000) 000-0000" maxlength="20">
            <mat-error *ngIf="editAccountForm.controls['PhoneNumber'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="editAccountForm.controls['UserRole'].value === 'Manager' || editAccountForm.controls['UserRole'].value == 'Member' || editAccountForm.controls['UserRole'].value == 'Claims'">
        <div class="col-md-12">
          <mat-label>Municipalities <span class="field-required-symbol">*</span></mat-label>
          <mat-form-field class="field-full-width" appearance="fill">
            <mat-select formControlName="Municipalities" multiple>
              <mat-option *ngFor="let municipality of municipalities" [value]="municipality.municipalityID" [disabled]="editAccountForm.controls['UserRole'].value == 'Member' && editAccountForm.controls['Municipalities'].value.length == 1 && !editAccountForm.controls['Municipalities'].value.includes(municipality.municipalityID)">{{municipality.fullName}}</mat-option>
            </mat-select>
            <mat-error *ngIf="editAccountForm.controls['Municipalities'].hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox formControlName="TwoFactor">Two Factor Authentication</mat-checkbox>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="dialog-action-buttons">
          <div mat-dialog-actions class="float-right">
            <button mat-button type="button" (click)="onNoClick()">Cancel</button>
            <button mat-button type="submit" mat-raised-button color="success" cdkFocusInitial class="submit-btn">Save</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
